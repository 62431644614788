$(document).foundation();


topbarBehaviour = {
  targetElement: $('#topbar'),
  hamburger: $('#hamburger'),
  isOpen: false,
  toggle: function () {
    if (this.isOpen) {
      this.targetElement.addClass('topbar-closed');
      this.targetElement.removeClass('topbar-open');
      this.hamburger.removeClass('open');
      this.isOpen = false;
    } else {
      this.targetElement.addClass('topbar-open');
      this.targetElement.removeClass('topbar-closed');
      this.hamburger.addClass('open');
      this.isOpen = true;
    }
  },
};

activBehaviour = {
  targetElement: $('section.actividades'),
  selector: $('section.actividades .selector'),
  content: $('section.actividades .content'),
  isActive: 2,
  switch: function (id) {
    if (id == this.isActive) {
      return;
    } else {
      this.selector.find('.actv-select[actv="'+this.isActive+'"]').toggleClass('active');
      this.selector.find('.actv-select[actv="'+id+'"]').toggleClass('active');
      this.content.find('.item[cid="'+this.isActive+'"]').toggleClass('active');
      this.content.find('.item[cid="'+id+'"]').toggleClass('active');
      this.isActive = id;
    }
  },
}

$('#hamburger').click(function() {
  topbarBehaviour.toggle();
});

$('section.actividades .selector .actv-select').each(function() {
  $(this).click(function() {
    activBehaviour.switch($(this).attr('actv'));
  });
});
